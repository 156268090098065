.dropoff-icon {
  /*height:165px;*/
  width: 210px;
  color: var(--brand-legacy-green);
  background: var(--brand-light-grey);
  border: solid 1.5px var(--brand-light-gold);
  margin: 20px 10px;
  padding: 17px 27px;
  transition: color 0.4s ease 0s, background 0.4s ease 0s;
  cursor: pointer;
}

.dropoff-icon:hover:not(:disabled),
.dropoff-icon:focus:not(:disabled) {
  color: var(--brand-light-grey);
  background: var(--brand-legacy-green);
}

.dropoff-icon.selected {
  color: var(--brand-light-grey);
  background: var(--brand-legacy-green);
  border-color: var(--brand-legacy-green);
}

.dropoff-icon:disabled {
  color: var(--brand-medium-grey);
  border-color: var(--brand-medium-grey);
  background-color: var(--brand-light-grey);
  cursor: default;
  position: relative;
}

.dropoff-svg-icon {
  font-size: 3rem;
}

.dropoff-icon-label {
  font-weight: bold;
  line-height: 1.29;
  font-size: 0.875rem;
}

.dropoff-icon-text {
  font-size: 0.625rem;
  font-style: italic;
}

/*Mobile Container breakpoint*/
@media only screen and (max-width: 870px) {
  .dropoff-icon {
    /* height: 105px; */
    width: 100%;
    margin: 20px 10px;
    /*margin:10px 0;*/
    padding: 18px 12px;
  }

  .dropoff-icon:first-child {
    /*margin-right:12px;*/
  }

  .dropoff-svg-icon {
    font-size: 2.1875rem;
  }

  .dropoff-icon-text {
    font-style: normal;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 425px) {
  .dropoff-icon {
    /* height: 105px; */
    width: 100%;
    /*margin: 20px 10px;*/
    margin: 10px 0;
    padding: 18px 12px;
  }
}
