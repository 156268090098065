.dropoff-screen {
  width: 870px;
}

.dropoff-screen h1 {
  margin-bottom: 0;
}

.dropoff-instructions {
  font-weight: 600;
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.29;
  color: var(--brand-dark-grey);
}

.dropoff-icons {
  display: flex;
  justify-content: center;
}

/* Shuttle Service Container */
.dropoff-shuttle-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #e3cea766;
  max-width: 440px;
  /*max-height:87px;*/
  margin: 0 auto;
  margin-bottom: 10px;
  overflow: hidden;
  color: var(--brand-legacy-green);
  border: 1px solid #00000000;
}

/* Disabled shuttle container */
.dropoff-shuttle-container.disabled {
  background-color: var(--brand-light-grey);
  color: var(--brand-medium-grey);
}

/* Selected shuttle container */
.dropoff-shuttle-container.selected {
  background-color: #dff0d866;
  border: 1px solid var(--brand-dark-green);
}

.dropoff-shuttle-container .shuttle-right {
  flex-grow: 1;
  text-align: left;
  padding: 18px 18px 18px 0;
}

.shuttle-right-title {
  font-size: 0.875rem;
  line-height: 1.29;
  font-weight: 600;
  margin: 0;
  margin-bottom: 4px;
}

.shuttle-right-description {
  font-size: 0.688rem;
  margin: 0;
}

.dropoff-shuttle-container .shuttle-left {
  min-width: 60px;
  width: 60px;
  padding: 18px;
}

.shuttle-selector {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 15px;
  padding: 0;
  margin-top: 5px;
}

.shuttle-selector:disabled {
  cursor: default;
}

/* Bottom Nav styling */

.dropoff-screen .cancel-button {
  margin-left: 0;
}

.dropoff-screen .continue-button {
  margin-right: 0;
}

.dropoff-screen .bottom-nav button {
  max-width: 215px;
}

/*Mobile Container breakpoint*/
@media only screen and (max-width: 870px) {
  .dropoff-screen {
    width: 100%;
    padding: 0 15px;
  }

  .dropoff-screen .heading {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 1.5rem;
    line-height: 1.25;
  }

  .dropoff-screen .dropoff-instructions {
    font-size: 0.625rem;
    margin-bottom: 0;
  }

  /*.dropoff-icons{*/
  /*  display:flex;*/
  /*  width:100%;*/
  /*  max-width:440px;*/
  /*  margin:0 auto;*/
  /*}*/

  .dropoff-icons {
    display: flex;
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
    padding: 5px;

    /*flex-wrap: wrap;*/
    /*flex-direction: column;*/
  }

  .dropoff-shuttle-container {
    height: auto;
    padding: 15px;
  }

  .dropoff-shuttle-container .shuttle-right {
    padding: 10px 0;
  }

  .dropoff-shuttle-container .shuttle-left {
    min-width: 40px;
    width: 40px;
    padding: 10px;
  }

  .shuttle-right-title {
    font-size: 0.8125;
  }

  .shuttle-right-description {
    font-size: 0.625rem;
  }
}

@media only screen and (max-width: 425px) {
  .dropoff-icons {
    display: flex;
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
  }

  .dropoff-shuttle-container {
    height: auto;
    padding: 15px;
  }
}
