.coming-soon-background {
    justify-content: center;
    width: 100vw;
    display: flex;
    background-image: linear-gradient(180deg, rgba(0,76,62,1) 35%, rgba(0,107,91,0.6) 100%);
    min-height: 85vh;
    max-height: 85vh;
    margin-top: -70px;
    margin-bottom: -70px;
}

.coming-soon-background-image {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .1) 70%, rgba(0, 0, 0, .3) 75%, rgba(0, 0, 0, 0.65) 100%), url("../assets/CBA_Storefront_alpha.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    z-index: 1;
}

.coming-soon-container {
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.coming-soon-heading {
    color: white;
    font-family: var(--text-crimson);
    font-weight: 500;
}

.coming-soon-heading > h1 {
    font-size: 12vh;
    font-variant-caps: small-caps;
    color: rgb(237, 166, 3);
    margin: 0;
}

.coming-soon-heading > h2 {
    /*font-size: 3vw;*/
    font-size: 5vh;
    margin-top: 0;
}

.coming-soon-info {
    width: 90%;
    color: white;
    font-weight: 500;
    padding: 0.5em;
}

.coming-soon-info > p,
.coming-soon-info > a {
    font-size: 2vh;
}

.coming-soon-info > p > a {
    text-decoration: none;
    color: rgb(237, 166, 3);
    font-weight: 700;
}

.coming-soon-info > p > a:hover,
.coming-soon-info > p > a:focus {
    color: #006b5b;
}

@media screen and (max-width: 769px) {

    .coming-soon-background {
        min-height: 70vh;
        max-height: 70vh;
        margin-bottom: 0;
    }

    .coming-soon-background-image {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .1) 60%, rgba(0, 0, 0, 0.8) 100%), url("../assets/CBA_Storefront_alpha.png");
        background-position: left center;
        background-size: cover;
    }

    .coming-soon-heading {
        width: 90%;
    }

    .coming-soon-heading > h1 {
        font-size: 8vh;
    }

    .coming-soon-heading > h2 {
        font-size: 3vh;
    }

    .coming-soon-info > p,
    .coming-soon-info > a {
        font-size: 1.7vh;
    }
}
