.weekview {
  width: 100%;
  max-width: 870px;
  min-width: 870px;
}

.weekview-picker-container {
  display: flex;
  flex-direction: column;
}

.weekview-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.weekview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.weekview-header p {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.125rem;
  color: var(--brand-dark-grey);
}

@media all and (max-width: 769px) {
  .weekview {
    min-width: 0;
  }
}
