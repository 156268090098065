.vehicle-selection-screen-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 65px;
}

.vehicle-selection-screen-container .heading {
  margin-top: 0;
}

.vehicle-screen h1, h4 {
  margin-bottom: 10px;
}

/*.vehicle-selection-screen-container .ui.selection.dropdown .vehicle-selection-dropdown {*/
/*.vehicle-selection-screen-container .ui.selection.dropdown {*/
.vehicle-selection-screen-container .ui.selection.dropdown {
  margin-left: 10px;
  width: 232px;
  /*width: 210px;*/
  /*width: 100%;*/
  height: 50px;
  border: 1px solid var(--brand-light-gold);
  border-radius: 0;
  background-color: var(--brand-light-grey);
  padding-top: 16px;
  color: var(--brand-dark-grey);

  &.vehicle-selection-dropdown {
    margin-left: unset;
    width: fit-content;
  }
}

.handle-vehicle-button {
  color: white;
  background-color: var(--brand-legacy-green);
  /*background-color: var(--brand-persimmon);*/
  border: none;
  transition: background-color 0.5s ease;
  height: 50px;
  width: 232px;
  /*flex-grow: 1;*/
  font-weight: bold;
  font-size: 0.8125rem;
  cursor: pointer;
  outline: none;
}

.handle-vehicle-button:hover:not(:disabled),
.handle-vehicle-button:focus:not(:disabled) {
  background-color: var(--brand-persimmon);
  /*background-color: var(--brand-legacy-green);*/
}

.handle-vehicle-button:disabled,
.handle-vehicle-button.fake-disabled {
  opacity: 0.5;
}

.handle-vehicle-button.fake-disabled:hover,
.handle-vehicle-button.fake-disabled:focus {
  background-color: var(--brand-persimmon);
}

.vehicle-selection-screen-container .ui.selection.dropdown .dropdown.icon {
  display: none;
}

.vehicle-selection-screen-container .ui.selection.dropdown .divider.default.text {
  font-size: 0.9375rem;
}

.vehicle-selection-screen-container .ui.selection.dropdown .active.selected.item {
  font-weight: 500;
  color: var(--brand-legacy-green);
}

.vehicle-selection-screen-container .ui.disabled.dropdown {
  opacity: 1;
}

/* Styling for Search */
.vehicle-selection-screen-container .ui.search.dropdown > .text {
  cursor: pointer;
}

.vehicle-selection-screen-container .ui.search.selection.dropdown > input.search {
  font-size: 0.9375rem;
  height: 100%;
  cursor: pointer;
}

/*.vehicle-selection-screen-container .vehicle-disclaimer {*/
.vehicle-disclaimer {
  margin-top: 0;
  color: var(--brand-dark-grey);
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 20px;
  /*margin-bottom: 0;*/
}

/* Mobile Styling */
/*@media all and (max-width: 1099px) {*/
@media all and (max-width: 769px) {
  .vehicle-screen h1 {
    margin-top: 20px;
    margin-bottom: 0;
    font-size:1.5rem;
    letter-spacing: normal;
    line-height: 1.25;
  }

  .vehicle-selection-heading h1, h4 {
    /*margin-top: 28px;*/
    margin-bottom: 0;
    /*font-size: 2.25rem;*/
    /*letter-spacing: normal;*/
    /*line-height: 1.25;*/
    /*padding: 0 15px;*/
  }

  .vehicle-selection-screen-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-bottom: 40px;
  }

  .vehicle-selection-screen-container .i-have {
    display: none;
  }

  .vehicle-selection-screen-container .ui.selection.dropdown {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .vehicle-selection-screen-container .vehicle-disclaimer {
    font-size: 0.625rem;
    margin-bottom: 0;
  }
}
