/* Max landing screen width */
.landing {
  width: 870px;
}

.landing-name {
  border: none;
  border-bottom: 2px solid var(--brand-legacy-green);
  font-size: 1.125rem;
  color: var(--brand-dark-grey);
  outline: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding: 2px 14px;
  min-width: 170px;
}

.landing-store {
  /* border-bottom: 2px solid var(--brand-legacy-green); */
  text-decoration: underline;
  /*margin:0 10px;*/
}

a.landing-store {
  color: var(--brand-legacy-green);
}

.landing-instructions {
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 26px;
  color: var(--brand-dark-grey);
}

.landing-other {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

.landing-other-container {
  width: 100%;
  max-width: 750px;
  min-width: 250px;
}

.landing-other-field {
  width: 100%;
  border: 1px solid var(--brand-light-gold);
  min-height: 110px;
  padding: 16px 10px;
  font-size: 0.9375rem;
  color: var(--brand-dark-grey);
  font-family: "Montserrat", sans-serif;
  outline: none;
}

.landing-other-instructions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.landing-instructions.other-field {
  text-align: left;
  margin-bottom: 11px;
}

.landing-instructions.other-field.character-count {
  text-align: right;
  margin-top: 5px;
  line-height: 18px;
  font-size: 0.75rem;
  font-weight: 500;
}

.landing-other-field::placeholder {
  color: var(--brand-medium-grey);
}

/* Landing Disclaimer */
.landing-disclaimer {
  padding: 20px;
  background-color: var(--brand-mint);
  min-height: 100px;
  max-width: 650px;
  width: 100%;
  min-width: 250px;
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: left;
}

.landing-disclaimer p {
  margin: 0;
  padding: 0;
  margin-top: 25px;
  font-size: 0.8125rem;
  font-weight: 500;
  color: var(--brand-dark-grey);
}

.landing-radio-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.landing-disclaimer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
}

.landing-disclaimer-heading {
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--brand-legacy-green);
}

.landing-disclaimer-text {
  text-align: left;
  margin: 0;
}

/* Contains inline sentence with our selection option */
.select-container {
  margin-top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

/* Contains our semantic UI dropdown */
.select-dropdown {
  display: inline-block;
  font-size: 0.8125rem;
  width: 320px;
  margin: 0 16px;
  min-height: 50px;
}

.select-dropdown .multiple.selection.dropdown {
  min-height: 50px;
  font-size: 0.9375rem;
  border-radius: 0;
  border-color: var(--brand-light-gold);
  font-family: "Montserrat", sans-serif;
  padding-right: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.select-dropdown .multiple.selection.dropdown:hover {
  border-color: var(--brand-light-gold);
}

.select-dropdown .ui.selection.active.dropdown .menu {
  border-color: var(--brand-light-gold);
  border-style: solid;
  border-radius: 0;
}

/* Fix for the default background of an item selection being applied */
.select-dropdown .ui.dropdown .menu .selected.item {
  background-color: #fff;
}

.select-dropdown .ui.dropdown .menu .selected.item:hover,
.select-dropdown .ui.dropdown .menu .selected.item:focus,
.select-dropdown .ui.dropdown .menu > .item:hover,
.select-dropdown .ui.dropdown .menu > .item:hover {
  background-color: var(--brand-light-gold);
}

.select-dropdown .multiple.selection.dropdown .divider.default.text {
  margin-top: 8px;
  color: var(--brand-medium-grey);
  font-weight: normal;
}

.select-dropdown .multiple.selection.dropdown .dropdown.icon {
  padding-top: 16px;
  display: none;
}

.select-dropdown .item .text {
  font-size: 0.9375rem;
  color: var(--brand-dark-grey);
}

/* UI pills for selection */
.select-dropdown .ui.multiple.dropdown > .label {
  height: 30px;
  border-radius: 2px;
  background-color: var(--brand-light-gold);
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  color: var(--brand-dark-grey);
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
}

.select-dropdown i.delete.icon {
  color: var(--brand-dark-grey);
  font-weight: normal;
  margin-left: 10px;
  margin-right: 0;
}

.select-dropdown .ui.multiple.dropdown > .label:hover {
  background-color: var(--brand-legacy-green);
  color: white;
}

.select-dropdown .ui.multiple.dropdown > .label:hover > i.delete.icon {
  color: white;
}

.landing-radio-container {
  display: flex;
  flex-direction: row;
}

.landing-radio-container .radio-button-container {
  margin-left: 0;
}

.landing-radio-label {
  color: var(--brand-legacy-green);
  font-weight: bold;
  font-size: 0.9375rem;
}

.landing-radio-label.disabled {
  opacity: 0.5;
}

.landing-radio-container.stay-and-wait {
  flex-grow: 1;
  justify-content: center;
  margin-left: 30px;
}

.landing-instructions.mobile {
  display: none;
}

.landing-services-section {
  width: 870px;
}

/* Services Section Styling*/
.landing-services-section-top {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 5px;
  margin-top: 15px;
  border-bottom: 1px solid var(--brand-dark-green);
}

.landing-services-section-top h2 {
  color: var(--brand-dark-green);
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}

/* Both icon buttons */
.landing-services-section-top button {
  background: none;
  border: none;
  margin-bottom: 5px;
  margin-left: 15px;
  cursor: pointer;
  padding: 0;
}

.landing-services-section-top .info-button {
  color: var(--brand-medium-grey);
}

.landing-services-section-top .collapse-button {
  /* Justify-right */
  margin-left: auto;
  color: var(--brand-persimmon);
}

.landing-services-section-grid {
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -9px;
}

.landing-other-error-text {
  text-align: left;
  line-height: 18px;
  font-size: 0.75rem;
  color: #ff453a;
  font-weight: 600;
  margin-top: 5px;
}

.landing-other-error {
  border: 1.5px solid #ff453a;
}

.recommended-services {
  padding: 0 8px;
}

.recommended-service-text {
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  color: #004c3e;
  font-weight: bold;
}

/* Mobile Styling */
@media all and (max-width: 769px) {
  .landing h1 {
    margin-top: 28px;
    margin-bottom: 0;
    font-size: 1.5rem;
    letter-spacing: normal;
    line-height: 1.25;
    padding: 0 15px;
  }

  .landing-instructions {
    margin: 10px 10px 22px 10px;
    font-size: 0.75rem;
  }

  .landing-i-need {
    display: none;
  }

  .select-dropdown {
    margin: 0 10px;
    width: 100%;
  }

  .select-container {
    margin-bottom: 12px;
  }

  .landing-other {
    padding: 0 15px;
  }

  .landing .heading.select-container {
    font-size: 1.5rem;
  }

  .landing-store {
    /*margin: 0 6px;*/
  }

  .landing-services-section {
    padding: 0 15px;
  }

  .landing-services-section-top h2 {
    font-size: 0.875rem;
  }

  /* Service Icons */
  .service-icon {
    height: 106px;
    width: 106px;
    margin: 7px;
    padding: 9px;
  }

  .service-icon .service-text {
    font-size: 0.75rem;
    align-content: baseline;
    margin-top: auto;
    margin-bottom: auto;
  }

  .service-icon .service-svg-icon {
    font-size: 35px;
    /*margin-bottom:8px;*/
    margin-bottom: -6px;
    align-content: center;
    height: auto;
  }

  /*@media only screen and (max-width: 600px) {*/
  /*  !*.service-icon .service-svg-icon{*!*/
  /*  !*  font-size:35px;*!*/
  /*  !*  margin-bottom: -6px;*!*/
  /*  !*  align-content: center;*!*/
  /*  !*  height: auto;*!*/
  /*  !*}*!*/

  /*  .service-icon .service-text{*/
  /*    font-size:0.65rem;*/
  /*    align-content: baseline;*/
  /*    margin-top: auto;*/
  /*    margin-bottom: auto;*/
  /*  }*/
  /*}*/

  .landing-instructions.other-field,
  .landing-other-field {
    font-size: 0.875rem;
  }
}

/*Fix container breakpoint*/
@media only screen and (max-width: 870px) {
  .landing,
  .landing-services-section {
    max-width: 870px;
    width: 100%;
  }
}

/*Fix semantic UI breakpoint*/
@media only screen and (max-width: 767px) {
  .ui.selection.dropdown .menu {
    max-height: 350px;
  }
}
