.sign-in-button {
    color: white;
    background-color: var(--brand-persimmon);
    border: none;
    transition: background-color 0.5s ease;
    /*height: 25px;*/
    height: 30px;
    /*margin: 5px;*/
    /*margin: 0 20px;*/
    margin-right: 20px;
    flex-grow: 1;
    max-width: 105px;
    font-weight: bold;
    font-size: 0.8125rem;
    /*font-size: 0.9rem;*/
    cursor: pointer;
    outline: none;
}

.sign-in-button:hover:not(:disabled),
.sign-in-button:focus:not(:disabled) {
    background-color: var(--brand-legacy-green);
}

.sign-in-button:disabled,
.sign-in-button.fake-disabled {
    opacity: 0.5;
}

.sign-in-button.fake-disabled:hover,
.sign-in-button.fake-disabled:focus {
    background-color: var(--brand-persimmon);
}