.datepicker {
  border: 1px solid var(--brand-light-gold);
  background-color: var(--brand-light-grey);
  margin: 5px 0;
  min-height: 50px;
  /* padding: 13px 10px 7px 20px; */
  font-size: 0.9375rem;
  color: var(--brand-dark-grey);
  flex-grow: 1;
}

.datepicker h2 {
  font-size: 0.9375rem;
  margin: 0;
  margin-right: 25px;
  width: 30px;
}

.datepicker-head .toggle-icon {
  padding: 0;
  border: none;
  background: none;
  color: var(--brand-persimmon);
  outline: none;
  cursor: pointer;
}

.datepicker-head {
  width: 100%;
  display: flex;
  flex-direction: row;

  margin: 13px 0 7px 0;
  padding: 0 10px 0 20px;
}

.datepicker-date {
  flex-grow: 1;
  text-align: left;
  margin: 0;
}

.datepicker-times-available {
  margin: 0;
  margin-right: 10px;
}

.datepicker-times-available.unavailable {
  color: var(--brand-medium-grey);
}

.datepicker-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: -5px;

  margin-bottom: 7px;
  padding: 0 10px 0 20px;
}

.datepicker-btn {
  background-color: white;
  color: var(--brand-legacy-green);
  width: 130px;
  height: 50px;
  border: 1px solid var(--brand-legacy-green);
  font-weight: bold;
  font-size: 0.875rem;
  font-weight: 600;
  outline: none;
  margin: 5px;
  transition: color 0.5s ease, background-color 0.5s ease;
}

.datepicker-btn:focus,
.datepicker-btn:hover {
  background-color: var(--brand-legacy-green);
  color: white;
}

.datepicker-btn:disabled {
  background: none;
  color: var(--brand-medium-grey);
  border: none;
  cursor: default;
}

.datepicker-btn.selected {
  color: white;
  background-color: var(--brand-legacy-green);
}

/* Mobile Styling */
@media all and (max-width: 769px) and (max-width: 1024px) {
  .datepicker {
    padding: 5px;
    /*height: 30px;*/
  }

  .datepicker-button-container {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    padding: 0;
  }

  .datepicker-btn {
    width: 75px;
    height: 40px;
    font-size: 0.75rem;
    padding: 3px;
  }

  .datepicker-head {
    margin-top: 8px;
    padding: 0 10px;
    margin-bottom: 0;
  }

  .datepicker-date {
    min-width: 70px;
  }
  .datepicker-times-available {
    text-align: end;
  }
}
