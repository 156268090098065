header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  height: 100px;
  background-color: #fafafa;
  display: flex;
  box-shadow: 0 0.1em 0.2em rgb(0 0 0 / 22%);
}

.header-full {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: stretch;
  backface-visibility: hidden;
  width: 100%;
}

/*.header-full::before{*/
/*  content:'';*/
/*  position:absolute;*/
/*  left:0;*/
/*  top:0;*/
/*  height:100%;*/
/*  width:5%;*/
/*  background-color:#FAFAFA;*/
/*  z-index:1;*/
/*}*/

.header-logo {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  width: 20%;
  box-sizing: border-box;
}

.header-logo .header-logo-container img {
  display: block;
  margin: 0 auto;
  max-width: 90%;
}

.header-logo-container {
  width: 100%;
  text-align: center;
  position: relative;
  padding: 0.5em 0 0;
  transform: translate3d(0, 0, 0);
  display: block;
}

/*Provides angled shadows*/
.header-logo-holder {
  position: relative;
  z-index: 1;
  transform: translate3d(0, 0, 0);
  padding-bottom: 16px;
}

/*.header-logo-holder::before{*/
/*  width: 50%;*/
/*  height: 2rem;*/
/*  box-shadow: 10px 14px 1rem -6px rgb(0 0 0 / 55%);*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: 0.75rem;*/
/*  left: 0;*/
/*  transform: rotate(9deg);*/
/*  transform-origin: 0% 100%;*/
/*  z-index: -1;*/
/*  transition: transform .4s ease;*/
/*}*/

/*.header-logo-holder::after{*/
/*  content: '';*/
/*  position: absolute;*/
/*  width: 50%;*/
/*  height: 2rem;*/
/*  bottom: 0.75rem;*/
/*  right: 0;*/
/*  box-shadow: -10px 14px 1rem -6px rgb(0 0 0 / 55%);*/
/*  transform: rotate(-9deg);*/
/*  transform-origin: 100% 100%;*/
/*  z-index: -1;*/
/*  opacity: 1;*/
/*  transition: transform .4s ease;*/
/*}*/

/*.header-logo-wrapper::after{*/
/*  content: '';*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 0;*/
/*  width: 50%;*/
/*  height: 100%;*/
/*  transform: skewY(*/
/*-8deg*/
/*);*/
/*  transform-origin: bottom right;*/
/*  z-index: -1;*/
/*  transition: transform .4s ease;*/
/*  background-color: #FFFFFF;*/
/*}*/

.header-logo-wrapper {
  /*padding-top: 2.7rem;*/
  padding-top: 0;
  transition: padding 0.4s ease;
  position: relative;
  z-index: 3;
  transform: translate3d(0, 0, 0);
}

/*.header-logo-wrapper::before{*/
/*  content: '';*/
/*  position: absolute;*/
/*  left: 0;*/
/*  top: 0;*/
/*  width: 50%;*/
/*  height: 100%;*/
/*  transform: skewY(*/
/*8deg*/
/*);*/
/*  transform-origin: bottom left;*/
/*  z-index: -1;*/
/*  transition: transform .4s ease;*/
/*  background-color: #FAFAFA;*/
/*}*/

.header-logo-wrapper img:nth-child(1) {
  width: 66px;
  height: 31px;
  margin-bottom: 0.25rem;
}

.header-logo-wrapper img:nth-child(2) {
  /*margin-top:1px;*/
  /*display: block;*/
  /*margin: 0 auto;*/
  /*max-width: 90%;*/
}

.header-center {
  width: 80%;
  flex-grow: 1;
  padding-left: 1%;
}

.header-right {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #e3cea7;
}

.header-phone {
  flex-grow: 1;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 0;
}

.header-phone a {
  color: #006b5b;
  text-decoration: none;
  cursor: default;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.header-phone svg {
  margin-right: 8px;
}

.header-top {
  height: 50%;
}

.header-top ul {
  display: flex;
  height: 100%;
  list-style-type: none;
  margin: 0;
}

.header-top ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-top-style: solid;
  border-width: 2px;
  transition: transform 0.4s ease;
  transform: scaleX(0);
}

.header-top ul li:hover > a::before,
.header-top ul li:focus > a::before {
  transform: scaleX(1);
}

.header-top ul li {
  display: flex;
  flex: 0 1 auto;
}

.header-location,
.header-top ul li a {
  text-decoration: none;
  color: #006b5b;
}

.header-top ul li a {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 3px 14px;
  font-size: 0.875rem;
}

.header-location svg {
  height: 15px;
  width: 15px;
}

.header-location {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding-left: 20px;
  transition: color 0.4s ease 0s;
  margin-right: 20px;
}

.header-location-name {
  font-weight: 700;
}

.header-location-name a {
  transition: color 0.4s ease 0s;
}

.header-location-name a::before {
  border-top-color: transparent;
}

.header-location-name:hover > a::before,
.header-location-name:focus > a::before {
  transform: none;
}

.header-location-name:hover > a,
.header-location-name:focus > a,
.header-location:hover,
.header-location:focus {
  color: rgb(237, 166, 3);
}

.schedule-btn {
  width: 85%;
  background-color: #fa6625;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 3px;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  font-weight: 700;
  transition: background-color 0.4s ease 0s;
}

.schedule-btn:hover {
  background-color: #006b5b;
}

.schedule-btn svg path {
  fill: white;
}

.schedule-btn svg {
  margin-right: 6px;
  height: 19px;
  width: 19px;
}

.header-nav-main {
  height: 50%;
  display: flex;
  align-items: center;
}

.header-nav-main nav {
  height: 100%;
}

.header-nav-main nav ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  height: inherit;
}

.header-nav-main nav ul li,
.header-nav-main nav ul li .header-dd-link {
  height: inherit;
  display: flex;
  align-items: center;
}

.header-nav-main ul li .header-dd-link {
  color: #006b5b;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.375rem;
  font-family: var(--text-crimson);
  letter-spacing: 2px;
  position: relative;
  padding-right: 11px;
}

.header-services-link a,
.header-about-link a {
  padding: 0 11px;
}

.header-nav-main ul li:not(:first-child):hover > .header-dd-link::before {
  transform: scaleX(1);
}

.header-nav-main ul li:not(:first-child) .header-dd-link::before {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  transition: transform 0.5s ease 0s;
  background-color: #e3cea7;
  transform-origin: center;
  transform: scaleX(0);
  top: 0;
  left: 0;
}

.header-home-icon {
  margin-right: 16px;
  height: 33px;
  width: 33px;
}

.header-home-icon:hover > path,
.header-home-icon:focus > path {
  fill: #006b5b;
}

.header-home-icon path {
  fill: #f5a800;
  transition: fill 0.4s ease 0s;
}

/* Hidden Services Dropdown */
.header-dd {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background-color: #006b5b;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: 0 9px 18px rgb(0 0 0 / 30%);
  padding: 36px 0;
  transform: translateY(-100%);
  z-index: -2;
  transition: opacity 0.75s ease 0s, transform 0.75s ease 0s;
  opacity: 0;
}

.header-services-link:hover > .header-dd-services,
.header-about-link:hover > .header-dd-about {
  opacity: 1;
  transform: translateY(0);
}

.header-dd-nav {
  width: auto;
  margin: 0 auto;
  position: relative;
  padding-left: 3%;
  padding-right: 3%;
  max-width: 55.556em;
}

.header-dd-nav::after {
  content: " ";
  background-image: url(../assets/dropdown-img.png);
  display: block;
  width: 383px;
  height: 149px;
  position: absolute;
  bottom: 0;
  left: -45%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

.header-dd-nav ul {
  flex-wrap: wrap;
  flex-direction: row;
}

.header-dd-nav li,
.header-dd-nav a {
  width: 32%;
  padding: 2%;
  flex: 0 1 auto;
  text-align: left;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-decoration: none;
  position: relative;
  transition: padding 0.35s ease 0s, color 0.35s ease 0s;
}

.header-dd-nav a:hover {
  padding-left: calc(2% + 15px);
}

.header-dd-nav a:hover::before {
  opacity: 1;
  left: 10px;
}

/*adds arrow in front of text*/
.header-dd-nav a::before {
  display: block;
  position: absolute;
  top: calc(50% - 6px);
  left: -10px;
  opacity: 0;
  transition: opacity 0.35s ease 0s, left 0.35s ease 0s;
  content: "";
  color: #ffffff;
  border-right: 4px solid white;
  border-bottom: 4px solid white;
  transform: rotate(-45deg);
  height: 7px;
  width: 7px;
}

/* Mobile Header */

.header-mobile {
  width: 100%;
  position: relative;
  display: none;
}

.header-m-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}

.header-m-logo img {
  max-height: 62px;
  width: auto;
}

.header-m-trigger {
  position: absolute;
  left: 10px;
  top: calc(50% - 24px);
}

.header-m-trigger button {
  height: 50px;
  width: 50px;
  border: none;
  background: none;
}

.header-menu-bar {
  display: block;
  width: 100%;
  height: 4px;
  transition: margin-top 0.25s ease 0.25s, transform 0.25s ease;
  border-radius: 0.222em;
  background-color: #f5a800;
}

.header-menu-bar + .header-menu-bar {
  margin-top: 8px;
}

.header-m-opened .header-menu-bar + .header-menu-bar {
  margin-top: -0.278em;
  transform: rotate(45deg);
  transition: margin-top 0.25s ease, transform 0.25s ease 0.25s;
}

.header-m-opened .header-menu-bar:first-child {
  transform: rotate(-45deg);
  transition: transform 0.25s ease 0.25s;
}

.header-mobile-nav {
  position: absolute;
  top: 100%;
  transform: translateX(-100%);
  width: 80%;
  max-width: 100%;
  display: block;
  transition: transform 0.5s ease 0s, opacity 0.5s ease 0s, color 0.5s ease 0s,
    background-color 0.5s ease 0s, box-shadow 0.5s ease 0s, right 0.5s ease 0s,
    left 0.5s ease 0s, height 0.5s ease 0s;
  background-color: #efefef;
  overflow: auto;
  height: 100vh;
  border: 0;
  left: 0;
  box-shadow: 0.833em 1.111em 2.222em rgb(0 0 0 / 15%);
  padding: 0;
  background-color: #004c3e;
  opacity: 0;
}

.header-mobile-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.header-mobile-nav li {
  border-bottom: 1px solid white;
  padding: 20px 15px;
}

.header-mobile-nav.header-m-opened {
  transform: translateX(0);
  opacity: 1;
}

.header-mobile-nav a {
  text-decoration: none;
  color: white;
  letter-spacing: 0.1rem;
  font-family: var(--text-crimson);
  font-weight: 700;
  font-size: 0.875rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-mobile-nav svg {
  margin-top: -5px;
  margin-right: 7px;
  height: 24px;
  width: 14px;
}

/* Screen sizes below 768px for mobile + tablet*/
@media all and (max-width: 769px) {
  header {
    height: 80px;
  }

  .header-mobile {
    display: block;
  }

  .header-full {
    display: none;
  }
}

/*@media all and (min-width: 770px) and (max-width: 1130px){*/
@media all and (min-width: 770px) and (max-width: 1023px) {
  /*.header-logo{*/
  /*  width:20%;*/
  /*}*/

  /*.header-center{*/
  /*  margin-left:20%;*/
  /*}*/

  .header-phone {
    font-size: 0.8rem;
  }

  .header-top ul {
    padding-left: 0;
  }

  .header-top ul li a {
    font-size: 0.8rem;
  }

  .header-location {
    padding-left: 0;
  }
}

@media print {
  header {
    position: absolute;
  }
}
