.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25vh;
}

.loading-text {
    color: #004c3e;
    font-family: "Crimson Text", serif;
}

.dots::after {
    content: '';
    display: inline-block;
    width: 1em;
    text-align: left;
    animation: dots 1.5s steps(4, end) infinite;
}

.loading-logo {
    animation: fadeLogo 1.5s linear infinite;
}

@keyframes dots {
    0%, 20% {
        content: '';
    }
    40% {
        content: '.';
    }
    60% {
        content: '..';
    }
    80%, 100% {
        content: '...';
    }
}

@keyframes fadeLogo {
    0%, 100% { opacity: 0.1; }
    50% { opacity: 1; }
}