.bottom-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  max-width: 440px;
  margin: 0 auto;
}

.bottom-nav button {
  height: 50px;
  margin: 5px;
  flex-grow: 1;
  max-width: 210px;
  font-weight: bold;
  font-size: 0.8125rem;
  cursor: pointer;
  outline: none;
}

.continue-button {
  color: white;
  background-color: var(--brand-persimmon);
  border: none;
  transition: background-color 0.5s ease;
}

.continue-button:hover:not(:disabled),
.continue-button:focus:not(:disabled),
.cancel-button:hover,
.cancel-button:focus {
  background-color: var(--brand-legacy-green);
}

.continue-button:disabled,
.continue-button.fake-disabled {
  opacity: 0.5;
}

.continue-button.fake-disabled:hover,
.continue-button.fake-disabled:focus {
  background-color: var(--brand-persimmon);
}

.cancel-button {
  border: solid 1px var(--brand-legacy-green);
  color: var(--brand-dark-grey);
  background-color: white;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.cancel-button:hover,
.cancel-button:focus {
  color: white;
}

@media all and (max-width: 769px) {
  .bottom-nav {
    padding: 15px;
  }

  .bottom-nav button {
    width: 45%;
    margin: 0;
  }

  .bottom-nav button:first-child {
    margin-right: 12px;
  }
}

@media print {
  .bottom-nav {
    display: none;
  }
}
