.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-variant-ligatures: no-common-ligatures;
}

/* Temp for main content */
main {
  margin-top: 170px;
  margin-bottom: 70px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media all and (max-width: 769px) {
  main {
    margin-top: 150px;
    margin-bottom: 0;
  }
}

@media print {
  main {
    margin-top: 120px;
  }
}

sup {
  color: #ff671d;
}
