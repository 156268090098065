.services-modal {
  width: 710px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 10px;
  position: relative;
}

.services-modal h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--brand-dark-grey);
  margin-bottom: 14px;
  margin-top: 0;
}

.services-row {
  border-bottom: 1px solid var(--brand-dark-grey);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  text-align: left;
  color: var(--brand-dark-grey);
  align-items: center;
}

.services-row:first-of-type {
  border-top: 1px solid var(--brand-dark-grey);
}

.services-modal-icon {
  font-size: 50px;
  height: 50px;
  color: var(--brand-legacy-green);
  margin-right: 16px;
}

.services-row-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.services-row-title {
  font-size: 0.875rem;
  width: 120px;
  min-width: 120px;
  white-space: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.services-row-description {
  line-height: 1.33;
  font-size: 0.75rem;
  margin-right: 10px;
  flex-grow: 1;
}

.services-row-container {
  width: 110px;
}

.services-row-saw-icon {
  margin-right: 1px;
  font-size: 30px;
  color: var(--brand-legacy-green);
}

.services-row-saw {
  font-size: 0.75rem;
  line-height: 1.33;
  width: 80px;
  min-width: 80px;
  text-align: center;
}

.services-modal-close {
  position: absolute;
  top: -10px;
  font-size: 48px;
  right: -10px;
  height: 50px;
  width: 50px;
  padding: 0;
  border: none;
  background: none;
  color: #006b5b;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.services-modal-container {
  overflow-y: auto;
  max-height: 80vh;
  width: 100%;
}

.services-modal-close:hover,
.services-modal-close:focus {
  color: #008a75;
}

.service-icon-container-mobile {
  display: none;
}

/* Mobile Styling */
@media all and (max-width: 769px) {
  .services-row-saw-icon,
  .services-row-title,
  .services-modal-icon {
    display: none;
  }

  .services-row {
    padding: 5px 0;
  }

  .services-row-saw {
    font-size: 0.625rem;
    line-height: normal;
    width: 66px;
    min-width: 66px;
    text-align: center;
  }

  .services-row-description {
    line-height: normal;
    font-size: 0.625rem;
    margin-right: 7px;
    flex-grow: 1;
  }

  .service-icon-container-mobile {
    max-width: 80px;
    min-width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 4px;
  }

  .services-modal-icon-mobile {
    font-size: 35px;
    color: var(--brand-legacy-green);
    margin-bottom: 2px;
    height: 35px;
  }

  .services-row-title-mobile {
    font-size: 0.625rem;
    text-align: center;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    color: black;
  }

  .services-modal {
    width: 100%;
  }
}
