.service-icon {
  /* Desktop Height */
  height: 135px;
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 17px 9px;
  color: var(--brand-dark-green);
  border: 1.5px solid var(--brand-light-gold);
  background-color: var(--brand-light-grey);
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  margin: 5px 9px;
}

/* .service-icon:hover{
  background-color:var(--brand-legacy-green);
  color:white
} */

@media (hover: hover) {
  /* Selected Styles */
  .service-icon[role="switch"][aria-checked="true"],
  .service-icon:hover,
  .service-icon:focus {
    background-color: var(--brand-legacy-green);
    color: white;
  }
}

/* Selected Hover */
.service-icon[role="switch"][aria-checked="true"]:hover {
  background-color: var(--brand-highlight-green);
}

@media all and (max-width: 769px) {
  .service-icon[role="switch"][aria-checked="true"],
  .service-icon:focus {
    background-color: var(--brand-legacy-green);
    color: white;
  }
}

.service-icon .service-svg-icon {
  font-size: 50px;
  margin-bottom: 5px;
}

.service-icon .service-text {
  margin: 0;
  padding: 0;
  /*font-size:0.875rem;*/
  font-size: 0.7rem;
  /*font-size: 16px;*/
  font-weight: bold;
}
