.datetime {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.datetime-button-link {
  font-size: 0.9375rem;
  color: var(--brand-persimmon);
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  padding-left: 0;
  margin-bottom: 4px;
  transition: color 0.5s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.datetime-button-link:hover,
.datetime-button-link:focus {
  color: var(--brand-legacy-green);
}

.datetime-button-link svg {
  margin-right: 5px;
}

.datetime h1 {
  margin-bottom: 0;
}

.datetime .datetime-description {
  margin-top: 0;
  color: var(--brand-dark-grey);
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 40px;
}

/* Mobile Styling */
@media all and (max-width: 769px) {
  .datetime {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
  }

  .datetime h1 {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 1.5rem;
    letter-spacing: normal;
    line-height: 1.5;
  }

  .datetime-button-link {
    font-size: 0.8125rem;
  }
}
