.after-hours-modal {
  width: 524px;
  min-height: 355px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 20px 10px 20px;
  position: relative;
}

.after-hours-modal .title {
  text-align: left;
  margin-bottom: 30px;
}

.after-hours-modal p {
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--brand-legacy-green);
  margin-top: 0;
  margin-bottom: 0;
}

.after-hours-modal-close {
  position: absolute;
  top: -10px;
  font-size: 48px;
  right: -10px;
  height: 50px;
  width: 50px;
  padding: 0;
  border: none;
  background: none;
  color: #006b5b;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.after-hours-modal-close:hover,
.after-hours-modal-close:focus {
  color: #008a75;
}

.after-hours-modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.after-hours-modal .done-button {
  background-color: white;
  color: var(--brand-legacy-green);
  width: 190px;
  height: 50px;
  border: 1px solid var(--brand-legacy-green);
  font-weight: bold;
  font-size: 0.875rem;
  font-weight: 600;
  outline: none;
  transition: color 0.5s ease, background-color 0.5s ease;
  margin: 0 auto;
  cursor: pointer;
}

.after-hours-modal .done-button:hover,
.after-hours-modal .done-button:focus {
  color: white;
  background-color: var(--brand-legacy-green);
}

.after-hours-modal .number-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 190px;
}

.after-hours-modal .number-container {
  margin-bottom: 26px;
}

/* Our number 1 needs a margin to line up */
.after-hours-modal .number-container:first-child > .number div:first-child {
  margin-left: 3px;
  width: 42px;
}

.after-hours-modal .number-container .number {
  display: flex;
  margin-bottom: 0;
}

.after-hours-modal .number-container .number div:first-child {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 0.78;
  color: var(--brand-legacy-green);

  width: 45px;
  text-align: left;
  margin-right: 15px;
}

.after-hours-modal .number-container .number .number-dash {
  flex-grow: 1;
  height: 0px;
  border-bottom: 1px solid var(--brand-medium-grey);
  margin-left: 25px;
  margin-top: 13px;
}

.after-hours-modal .number-container .step-text {
  font-size: 1rem;
  color: var(--brand-dark-grey);
  text-align: left;
  line-height: 28px;
}

.after-hours-modal .contact-sentence {
  text-align: left;
  margin-bottom: 30px;
}

/* Mobile Styling */
@media all and (max-width: 769px) {
  .after-hours-modal {
    width: 100%;
  }
}
