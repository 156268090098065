.personal-info {
  font-size: 0.9375rem;
  font-family: Montserrat;
}

.personal-info-form {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin-bottom:30px; */
  margin-top: 15px;
  width: 100%;
  margin-bottom: 10px;
}

.personal-info-form label {
  width: 0;
  font-size: 0;
  opacity: 0;
  position: absolute;
}

.personal-info-form .form-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1000px;
  padding: 0 5px;
}

.personal-info-form .form-row.phone-row {
  margin-top: 5px;
}

.personal-info-form .input-item {
  flex-grow: 1;
  margin: 0 5px;
  max-width: 250px;
}

.personal-info-form .input-item {
  margin-top: 25px;
}

/* Email Errors */
.personal-info-form .input-item.show-error {
  margin-top: 0;
}

.personal-info-form .input-item.show-error input {
  border: 1px solid var(--brand-persimmon);
}

.personal-info-form .input-item.show-error input::placeholder {
  color: var(--brand-persimmon);
}

.personal-info-form .personal-info-names,
.personal-info-form .email-address {
  margin-top: 25px;
}

.personal-info-error,
.personal-info-first-time p.personal-info-error {
  font-size: 0.75rem;
  color: var(--brand-persimmon);
  text-align: left;
  margin: 0;
  height: 25px;
  font-weight: 500;
}

.email-address .personal-info-error {
  margin-left: 10px;
}

.first-time-selection-container {
  display: flex;
  flex-direction: column;
}

.personal-info-form input {
  color: var(--brand-dark-grey);
  padding: 16px 10px;
  max-width: 250px;
  background-color: var(--brand-light-grey);
  font-family: Montserrat;
  font-size: 0.9375rem;

  width: 100%;
  height: 50px;
  outline: none;
  border-radius: 0;
  border: 1px solid var(--brand-light-gold);
}

.personal-info-form input::placeholder {
  color: var(--brand-medium-grey);
}

.personal-info-update-box {
  height: 90px;
  max-width: 980px;
  width: 100%;
  display: flex;
  background-color: var(--brand-mint);
  justify-content: space-between;
  padding: 20px;
  padding-right: 10px;
}

.update-box-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.update-box-container .update-selection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 300px;
  margin-left: 10px;
}

.update-box-container .update-box-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.update-box-container .update-box-heading {
  font-weight: bold;
  color: var(--brand-legacy-green);
  margin-bottom: 6px;
}

.personal-info-first-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 980px;
  margin: 0 auto;
  margin-top: -15px;
  margin-bottom: 20px;
}

.first-time-question {
  margin-right: 60px;
  color: var(--brand-dark-grey);
  margin-top: 45px;
}

.personal-info-radio {
  display: inherit;
  margin-top: 25px;
}

.personal-info-radio.show-error {
  margin-top: 0;
}

.special-label {
  display: none;
}

.flag-dropdown {
  display: none;
}

.update-box-left p {
  margin: 0;
  padding: 0;
  text-align: left;
}

/* Mobile Styling */
@media all and (max-width: 769px) {
  .personal-info {
    padding-bottom: 10px;
  }

  .personal-info-form .form-row.phone-row {
    margin-top: 0;
  }

  /* Mobile Heading */
  .personal-info .heading {
    font-size: 1.5rem;
    line-height: 1.17;
    margin: 10px;
    margin-bottom: 20px;
  }

  .personal-info-form .form-row {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-top: 5px;
    flex-direction: column;
    padding: 0;
  }

  .personal-info-form .input-item {
    width: 100%;
    margin: 5px 0;
    max-width: 250px;
  }

  .personal-info-form {
    padding: 0 10px;
  }

  .personal-info-form .input-item:first-child,
  .personal-info-form {
    margin-top: 0;
  }

  .personal-info-form input {
    max-width: none;
  }

  .first-time-question {
    text-align: left;
    margin: 10px;
    margin-top: 30px;
  }

  .personal-info-update-box {
    flex-direction: column;
    height: initial;
  }

  .update-box-container .update-selection-container {
    margin-left: 30px;
  }

  .personal-info-radio {
    margin-top: -10px;
  }
}
