:root {
  --brand-legacy-green: #006b5b;
  --brand-persimmon: #ff671d;
  --brand-dark-grey: #434343;
  --brand-light-grey: #fafafa;
  --brand-medium-grey: #ababab;
  --brand-light-gold: #e3cea7;
  --brand-mint: #dff0d8;
  --brand-dark-green: #005649;

  --brand-highlight-grey: #dbdbdb;
  --brand-highlight-green: #007a68;

  --text-crimson: "Crimson Text", serif;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading {
  font-size: 2.25rem;
  line-height: 54px;
  color: var(--brand-dark-grey);
  font-family: var(--text-crimson);
  font-weight: 700;
  margin-top: 42px;
  margin-bottom: 8px;
}

@supports (-webkit-overflow-scrolling: touch) {
  input,
  textarea {
    font-size: 16px;
  }
}
