.no-location {
  /*margin-top: 80px;*/
  margin-top: -80px;
  margin-bottom: 40px;
  min-height: 500px;
  padding: 0 16px;
}

.care-plus-brand {
  color: #006b5b;
}

.care-plus-brand-plus {
  color: #f5a800;
}

.care-plus-heading {
  line-height: 45px !important;
}

.info-text {
  font-size: 1.25rem;
  line-height: 35px !important;
  margin: 0;
}

.store-locator {
  text-align: left;
  padding: 0 16px;
  height: 75dvh;
  width: 90dvw;

  @media (max-width: 767px) {
    height: 100%;
  }
}

#storepoint-container {
  height: 100% !important;
}

.mapboxgl-canvas {
  width: 100% !important;
}

.storepoint-distance {
  position: unset !important;
}