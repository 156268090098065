.calendarview {
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
}

.calendarview-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
}

.calendarview-datepicker {
  flex-grow: 1;
  width: 100%;
  max-width: 760px;
  margin-top: -5px;
}

.calendarview-datepicker .datepicker {
  width: 735px;
}

.calendarview-navbuttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.calendarview-navbuttons button {
  background: none;
  border: none;
  color: var(--brand-persimmon);
  font-weight: bold;
  font-size: 0.9375rem;
  cursor: pointer;
  outline: none;
  transition: color 0.5s ease;
}

.calendarview-navbuttons button:hover,
.calendarview-navbuttons button:focus {
  color: var(--brand-legacy-green);
}

.calendarview-nextday {
  margin-left: auto;
}

/* react-datepicker styling */
.react-datepicker {
  border-radius: 0;
  background-color: var(--brand-light-grey);
  border: solid 1px var(--brand-light-gold);
  font-family: "Montserrat", sans-serif;
  margin-right: 10px;
}

/* Month Arrows */
.react-datepicker__navigation {
  border: 0.4rem solid transparent;
  top: 15px;
  outline: none;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--brand-persimmon);
}
.react-datepicker__navigation--next {
  border-left-color: var(--brand-persimmon);
}

.react-datepicker__header {
  background: none;
  border-bottom: none;
}

.react-datepicker__current-month {
  color: var(--brand-legacy-green);
  text-transform: uppercase;
  font-size: 1.125rem;
  margin-top: 3px;
}

.react-datepicker__month-container {
  width: 320px;
  /* height:310px; */
}

.react-datepicker__month {
  margin-top: 10px;
  /* height:200px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.react-datepicker__week {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.react-datepicker__day {
  font-weight: 500;
  color: var(--brand-dark-grey);
  font-size: 1.125rem;
  outline: none;
  padding: 4px;
  border-radius: 2px;
  height: 35px;
  width: 35px;
}

.react-datepicker__day--disabled {
  color: var(--brand-medium-grey);
}

.react-datepicker__day:hover {
  border-radius: 2px;
}

.react-datepicker__day--today.react-datepicker__day--selected,
.react-datepicker__day--selected {
  background-color: var(--brand-legacy-green);
  color: white;
}

.react-datepicker__day--selected:hover {
  background-color: var(--brand-legacy-green) !important;
}

.react-datepicker__day--keyboard-selected {
  background: none;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #eee;
}

.react-datepicker__day--today {
  color: var(--brand-legacy-green);
  background: none;
  font-weight: bold;
}

.react-datepicker__day-names {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
}

.react-datepicker__day-name {
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--brand-dark-grey);
}

/* Mobile Styling */
@media all and (max-width: 769px) {
  .calendarview {
    margin-bottom: 0;
  }

  .calendarview-container {
    flex-direction: column;
  }

  .react-datepicker {
    width: 100%;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .calendarview-datepicker {
    margin-top: 3px;
  }

  .calendarview-datepicker .datepicker {
    width: auto;
  }

  .calendarview-navbuttons button {
    font-size: 0.8125rem;
  }
}

@media all and (max-width: 1025px) {
  .calendarview-container {
    flex-direction: column;
  }

  .react-datepicker {
    width: 100%;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .calendarview-datepicker {
    margin-top: 3px;
  }

  .calendarview-datepicker .datepicker {
    width: auto;
  }

  .calendarview-navbuttons button {
    font-size: 0.8125rem;
  }
}
