.fullscreen-modal {
  background-color: #000000aa;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
}

.modal-container {
  max-height: 90%;
  /* width:750px; */
  margin-top: 2%;
  background-color: white;
  opacity: 1;
  padding: 20px;
  border: 1px solid var(--brand-legacy-green);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.modal-container p {
  margin: 0;
  padding: 0;
  font-size: 0.9375rem;
  line-height: 1.5;
  flex-grow: 1;
}

.modal-container p.appointment-id {
  flex-grow: 0;
  margin-bottom: 30px;
}

.modal-container .heading {
  margin-top: 0;
  line-height: 1;
  margin-bottom: 20px;
}

.modal-container .bottom-nav button {
  width: 110px;
  height: 50px;
}

.modal-container .heading.time-modal-head {
  margin-top: 20px;
  font-size: 1.675rem;
  margin-bottom: 45px;
}

/* Mobile Styling */
@media all and (max-width: 769px) {
  .modal-container {
    max-width: 90%;
    padding: 11px;
  }

  .modal-container .heading {
    font-size: 1.75rem;
    line-height: 24px;
  }

  .modal-container .heading.time-modal-head {
    margin-top: 0;
    font-size: 1.675rem;
    margin-bottom: 10px;
  }
}

@media print {
  .fullscreen-modal {
    display: none;
  }
}
