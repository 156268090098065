.vehicle-selection-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 65px;
}

.vehicle-selection-container .heading {
  margin-top: 0;
}

.vehicle-screen h1 {
  margin-bottom: 0;
}

.vehicle-selection-container .ui.selection.dropdown {
  margin-left: 10px;
  height: 50px;
  border: 1px solid var(--brand-light-gold);
  border-radius: 0;
  background-color: var(--brand-light-grey);
  padding-top: 16px;
  color: var(--brand-dark-grey);
}

.vehicle-selection-container .ui.selection.dropdown .dropdown.icon {
  display: none;
}

.vehicle-selection-container .ui.selection.dropdown .divider.default.text {
  font-size: 0.9375rem;
}

.vehicle-selection-container .ui.selection.dropdown .active.selected.item {
  font-weight: 500;
  color: var(--brand-legacy-green);
}

.vehicle-selection-container .ui.disabled.dropdown {
  opacity: 1;
}

/* Styling for Search */
.vehicle-selection-container .ui.search.dropdown > .text {
  cursor: pointer;
}

.vehicle-selection-container .ui.search.selection.dropdown > input.search {
  font-size: 0.9375rem;
  height: 100%;
  cursor: pointer;
}

/*.vehicle-selection-container .vehicle-disclaimer {*/
.vehicle-disclaimer {
  margin-top: 0;
  color: var(--brand-dark-grey);
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 40px;
}

/* Mobile Styling */
@media all and (max-width: 1099px) {
  .vehicle-screen h1 {
    margin-top: 20px;
    margin-bottom: 0;
    font-size:2.25rem;
    letter-spacing: normal;
    line-height: 1.5;
  }

  .vehicle-selection-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-bottom: 40px;
  }

  .vehicle-selection-container .i-have {
    display: none;
  }

  .vehicle-selection-container .ui.selection.dropdown {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .vehicle-selection-container .vehicle-disclaimer {
    font-size: 0.625rem;
    margin-bottom: 0;
  }
}
