.breadcrumbs {
  background-color: var(--brand-dark-green);
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  z-index: -3;
}

/* Container that is used to center our breadcrumbs menu and allow it to grow */
.breadcrumbs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 800px;
  width: 100%;
  min-width: 250px;
  margin: 0 40px;
}

.breadcrumbs-dash {
  flex-grow: 1;
  height: 0px;
  border-bottom: 2px solid white;
  margin: 0 6px;
}

@media all and (max-width: 769px) {
  .breadcrumbs {
    min-height: 50px;
    top: 80px;
  }

  .breadcrumbs-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media print {
  .breadcrumbs {
    display: none;
    position: absolute;
  }
}
