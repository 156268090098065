footer {
  background-color: #004c3e;
  padding: 70px 57px 16px;
  color: #fff;
}

/* Links selector */
footer nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

footer nav ul li a:hover {
  color: #f5a800;
  opacity: 1;
}

footer nav ul li:first-child a {
  padding-left: 0;
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer nav {
  padding: 36px 0;
}

footer nav ul li a {
  display: block;
  padding: 0.17408rem 1.088rem;
  /* font-size: 0.875rem; */
  font-size: 1.25rem;
  font-weight: 700;
  font-family: var(--text-crimson);
  transition: color 0.4s ease 0s, opacity 0.4s ease 0s;
  opacity: 0.6;
  outline: none;
}

footer nav ul + ul {
  margin-top: 20px;
}

.ft-flex {
  display: flex;
  flex-direction: row;
}

.ft-top {
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: solid 2px rgba(255, 255, 255, 0.6);
  padding-bottom: 1em;
}

.ft-bottom {
  flex-direction: column;
}

.ft-bottom-logo {
  width: 100%;
  height: auto;
  opacity: 0.6;
}

.ft-contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.ft-phone {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
  font-weight: 700;
  cursor: default;
  margin-bottom: 27px;
}

.ft-icon-location {
  height: 20px;
  width: 20px;
}

.ft-icon-location path {
  fill: #f5a800;
}

.social-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.social-container path {
  opacity: 0.6;
  transition: fill 0.4s ease, opacity 0.4s ease;
}

.social-container svg {
  opacity: 1;
  height: 22px;
  width: 22px;
}

.social-container a:hover > svg g path,
.social-container a:focus > svg g path {
  fill: #f5a800;
  opacity: 1;
}

.social-container a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  flex-grow: 1;
  border-bottom: solid 3px transparent;
  transition: border-color 0.4s ease 0s, opacity 0.4s ease 0s;
}

.social-container a:hover,
.social-container a:focus {
  border-bottom: solid 3px #f5a800;
}

.ft-social-icon {
  fill: white;
  opacity: 0.6;
  height: 21px;
  width: 21px;
}

.ft-payment-row {
  display: flex;
  padding: 18px 0;
}

.ft-payment-row p {
  display: inline-block;
  white-space: pre-wrap;
  opacity: 0.6;
  font-size: 1.125rem;
  line-height: 28.8px;
}

.ft-payment-row ul {
  display: inline-flex;
  padding-left: 25px;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
}

.ft-payment-row li {
  padding-right: 25px;
  opacity: 0.6;
}

.ft-disclaimer {
  display: flex;
  justify-content: space-between;
  opacity: 0.6;
  align-items: center;
}

.ft-disclaimer p {
  text-align: center;
  font-size: 1.25rem;
  align-items: center;
}

.ft-disclaimer small {
  font-size: 1rem;
}

.btn-footer {
  text-align: center;
  background-color: #fff;
  color: #fa6625;
  padding: 20px 46px;

  position: relative;
  box-shadow: 0 0.5rem 1em -0.25em rgb(0 0 0 / 33%);
  transition: transform 0.4s ease, color 0.4s ease, background-color 0.4s ease;
  cursor: pointer;
  font-weight: 700;
}

.btn-footer:hover {
  color: #006b5b;
}

.btn-location {
  font-size: 1.25rem;
}

/* Screen sizes below 768px for mobile + tablet*/
@media all and (max-width: 769px) {
  footer {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 0;
  }

  footer nav {
    padding: 16px;
  }

  footer nav ul {
    flex-wrap: wrap;
  }

  footer nav ul + ul {
    margin-top: 0;
  }

  footer nav ul li {
    width: 48%;
  }

  footer nav ul li a {
    font-size: 0.875rem;
    padding: 3px 7px;
    line-height: 22px;
  }

  .ft-contact-info {
    align-items: center;
  }

  .ft-right {
    width: 100%;
  }

  /* .ft-payment-row p{
    font-size: 0.875rem;
    line-height: 22px;
  } */

  .ft-payment-row {
    display: none;
  }

  .ft-disclaimer {
    flex-direction: column;
    font-size: 0.9375rem;
  }

  .ft-disclaimer p {
    font-size: 1rem;
    padding: 0;
  }

  .ft-disclaimer small {
    font-size: 0.75rem;
    line-height: 20px;
  }

  .ft-top {
    border-bottom: none;
    padding: 0;
  }

  .ft-bottom-logo {
    display: none;
  }

  .ft-phone {
    display: flex;
    justify-content: flex-end;
    font-size: 2rem;
    font-weight: 700;
    cursor: default;
    margin-bottom: 0px;
  }

}

@media print {
  footer {
    display: none;
  }
}
