.review-screen {
  display: flex;
  justify-content: center;
}

.review-container {
  max-width: 800px;
  padding: 0 27px;
  width: 100%;
}

.review-container .heading {
  margin-bottom: 5px;
}

.review-sentence {
  font-weight: 600;
  color: var(--brand-dark-grey);
  line-height: 1.29;
  font-size: 0.875rem;
  margin: 0;
  width: 750px;
}

.confirmation-sentence {
  font-weight: 500;
  color: var(--brand-dark-grey);
  line-height: 1.29;
  font-size: 0.875rem;
  margin: 0;
  width: 750px;
}

.info-card-container {
  margin-top: 35px;
}

.review-info-card {
  display: flex;
  flex-direction: row;
  /* min-height:65px; */
  position: relative;
  margin-top: 20px;
}

.review-info-card .edit-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  font-weight: 600;
  color: var(--brand-dark-green);
  font-size: 0.9375rem;
  outline: none;
  cursor: pointer;
  transition: color 0.5s ease;
}

.review-info-card .edit-button:hover {
  color: var(--brand-highlight-green);
}

.review-info-card .info-card-content {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid var(--brand-dark-grey);
  margin-left: 32px;
  padding-bottom: 14px;
  padding-right: 69px;
  overflow: hidden;
}

.review-icon {
  color: var(--brand-legacy-green);
}

.review-services {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: var(--brand-dark-grey);
}

.review-services li {
  list-style: none;
  margin: 0;
  /*line-height:1.67;*/
  text-align: left;
  font-size: 0.9375rem;
  font-weight: 600;
}

.review-heading {
  /*line-height:1.67;*/
  font-weight: bold;
  text-align: left;
  color: var(--brand-dark-grey);
  font-size: 0.9375rem;
  margin-bottom: 5px;
  font-weight: 600;
}

.review-text {
  color: var(--brand-medium-grey);
  font-size: 0.9375rem;
  font-weight: 500;
  /*line-height:1.67;*/
  text-align: left;
}

.review-type-text {
  color: var(--brand-medium-grey);
  font-size: 0.75rem;
  font-weight: 500;
  /*line-height:1.67;*/
  text-align: left;
}

.review-tel {
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.67;
  text-decoration: none;
  color: var(--brand-dark-green);
  transition: color 0.5s ease;
}

.review-tel:hover {
  color: var(--brand-persimmon);
}

.review-phone-icon {
  margin-left: 10px;
}

.review-shuttle-container {
  margin-top: 30px;
  background-color: var(--brand-mint);
  min-height: 100px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-bottom: 25px;
}

.review-shuttle-right {
  flex-grow: 1;
  text-align: left;
}

.review-shuttle-right .shuttle-title {
  font-weight: bold;
  color: var(--brand-legacy-green);
  font-size: 0.9375rem;
  margin-bottom: 10px;
}

.review-shuttle-container .shuttle-check {
  margin-top: 5px;
  margin-left: -8px;
  margin-right: 10px;
}

.review-shuttle-right p {
  margin: 0;
  padding: 0;
  font-size: 0.9375rem;
}

.confirmation-heading,
.confirmation-number-row {
  display: none;
}

.review-other-text {
  font-weight: normal;
}

.review-other-item {
  width: 100%;
  overflow-wrap: break-word;
}

.review-shuttle-wrapper .dropoff-shuttle-container {
  max-width: 695px;
  /*height:80px;*/
  margin-left: 56px;
  margin-top: 40px;
  margin-bottom: 35px;
}

/* Normal shuttle container */
.review-shuttle-wrapper .dropoff-shuttle-container {
  background-color: #e3cea766;
  border-width: 2px;
}

/* Selected shuttle container */
.review-shuttle-wrapper .dropoff-shuttle-container.selected {
  background-color: var(--brand-mint);
  border: 2px solid var(--brand-legacy-green);
}

.review-shuttle-wrapper .shuttle-right-description {
  /*font-size: .9375rem;*/
  font-size: 0.875rem;
  font-weight: 500;
  /*font-size: 1rem;*/
  /*line-height: 1.75;*/
}

.review-shuttle-wrapper .dropoff-shuttle-container .shuttle-left {
  min-width: 50px;
  width: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.review-shuttle-wrapper .shuttle-selector {
  font-size: 0.9375rem;
  margin-top: 4px;
}

.review-container > .review-sentence:nth-child(3) {
  margin-top: 15px;
}

.review-screen .after-hours-label {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.review-screen .dropoff-info-button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: var(--brand-medium-grey);
  margin-left: 10px;
  cursor: pointer;
}

/* Mobile Styling */
@media all and (max-width: 769px) {
  .review-heading,
  .review-text,
  .review-tel,
  .review-info-card .edit-button,
  .review-services li {
    font-size: 0.875rem;
    line-height: 18px;
  }

  .review-icon svg {
    height: 1rem;
  }

  .review-info-card .info-card-content {
    margin-left: 16px;
  }

  .review-phone-icon svg {
    height: 12px;
    width: 12px;
  }

  .review-container {
    padding: 0 15px;
  }

  .review-container .heading {
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: 18px;
  }

  .review-shuttle-right p.shuttle-question {
    margin-left: 5px;
  }

  .review-shuttle-right p {
    display: inline;
  }

  .review-container .review-sentence.please-review {
    font-size: 0.625rem;
  }

  .review-container .review-sentence {
    width: 100%;
  }

  .review-shuttle-right p {
    font-size: 0.8125rem;
  }

  /* Shuttle service */
  .review-shuttle-wrapper .dropoff-shuttle-container {
    max-width: 100%;
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .review-shuttle-wrapper .shuttle-right-description {
    font-size: 0.625rem;
    line-height: normal;
    margin-top: 5px;
  }

  .review-screen .bottom-nav {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Print view */
@media print {
  .booking-heading,
  .please-review,
  .ready-sentence {
    display: none;
  }

  .confirmation-heading,
  .confirmation-number-row {
    display: block;
  }

  .review-info-card .edit-button {
    display: none;
  }
}
