.radio-button {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  color: var(--brand-dark-grey);
}

.radio-button label {
  margin-left: 10px;
}

.radio-button-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-button-checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--brand-legacy-green);
}

/* On mouse-over, add a grey background color */
.radio-button-container:hover input ~ .radio-button-checkmark {
  background-color: #eee;
}

/* When the radio button is checked, add a blue background */
.radio-button-container input:checked ~ .radio-button-checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-button-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button-container input:checked ~ .radio-button-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-button-container .radio-button-checkmark:after {
  top: 2px;
  left: 2px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: var(--brand-legacy-green);
}

.radio-button-checkmark.disabled {
  opacity: 0.5;
  cursor: default;
}

.radio-button-checkmark.disabled:hover {
  background: white !important;
}
